import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
// import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
	apiKey: 'AIzaSyD5xAPctNmB-xZmhmuRfRK4G-uoq6Q0c4c',
	authDomain: 'generator-app-7677e.firebaseapp.com',
	projectId: 'generator-app-7677e',
	storageBucket: 'generator-app-7677e.appspot.com',
	messagingSenderId: '812760678316',
	appId: '1:812760678316:web:832c7eb31efff764e5c409',
	measurementId: 'G-SPG76K4R9Y'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig, 'growthbank-generator-app-7677e')
const db = getFirestore(app)
const auth = getAuth(app)
const functions = getFunctions(app, 'us-central1')
const analytics = getAnalytics(app)
const { firestore } = getFirestore(app)
const storage = getStorage(app)

if (window.location.hostname === 'localhost') {
	// connectFunctionsEmulator(functions, 'localhost', 5001)
}

export { app, db, auth, functions, httpsCallable, analytics, firestore, storage }
