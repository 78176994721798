import { app, functions, db } from '@/firebaseInit'
import { httpsCallable } from 'firebase/functions'
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore'
import store from '@/store'
import AUTH_ERROR_CODES from '@/constants/auth'
import { showDanger, showSuccess } from './AlertService'

const AUTH_CODES = {
	'auth/wrong-password': 'The password is incorrect!',
	'auth/user-not-found': 'User does not exist!'
}

export const setUserData = (res) =>
	// eslint-disable-next-line no-async-promise-executor
	new Promise(async (resolve, reject) => {
		try {
			const auth = getAuth(app)

			const { uid } = res
			const { claims } = await res.getIdTokenResult()
			await store.dispatch('updateCurrentCustomer', res)
			const docRef = doc(db, 'users', uid)
			const user = await getDoc(docRef)

			if (user.exists()) {
				const token = await auth.currentUser.getIdToken(true)
				localStorage.setItem('jwt', token)

				const data = {
					...user.data(),
					role: claims.role,
					account: {},
					subscriptions: []
				}

				if (auth.currentUser) {
					data.emailVerified = auth.currentUser.emailVerified
				}

				const snapshots = store.state.unsubscribe
				const accountRef = doc(db, 'accounts', data.accountId)
				const account = await getDoc(accountRef)

				data.account = account.data()
				await store.dispatch('updateCurrentAccount', account)
				const accountData = { data: account.data(), id: account.id, ref: account.ref }

				const q = query(
					collection(db, 'accounts', data.accountId, 'subscriptions'),
					where('status', 'in', ['trialing', 'active'])
				)

				const querySnapshot = await getDocs(q)

				// eslint-disable-next-line no-unused-vars
				let sub
				querySnapshot.forEach((subscription) => {
					data.subscriptions.push(subscription.data())
					sub = subscription.data()
					store.dispatch('updateCurrentPlan', subscription.data())
					snapshots.push(subscription)
				})

				// const startDate = DateTime.fromSeconds(data.createdAt.seconds).toISO()

				// userflow.identify(uid, {
				// 	firstName: data.firstName,
				// 	lastName: data.lastName,
				// 	name: data.fullName,
				// 	email: data.email,
				// 	plan: sub?.items[0].price?.product?.name || '',
				// 	signed_up_at: startDate
				// })

				store.dispatch('updateCustomerData', { data, id: user.id, ref: user.ref })
				snapshots.push(user)

				await store.dispatch('updateAccountData', accountData)
				snapshots.push(account)

				const documentsQuery = query(collection(db, 'projects'), where('uid', '==', uid))

				const documentsSnapshot = await getDocs(documentsQuery)
				const documents = []
				documentsSnapshot.forEach((document) => {
					documents.push({ id: document.id, ...document.data() })
					snapshots.push(document)
				})
				store.dispatch('updateDocuments', documents)

				const completionsQuery = query(collection(db, 'completions'), where('uid', '==', uid))

				const completionsSnapshot = await getDocs(completionsQuery)
				const completions = []
				completionsSnapshot.forEach((completion) => {
					completions.push({ id: completion.id, ...completion.data() })
					snapshots.push(completion)
				})
				store.dispatch('updateCompletions', completions)

				store.commit('setUnsubscribe', snapshots)
				store.commit('setUserData', data)
				resolve(data)
			}
		} catch (error) {
			reject(error)
		}
	})

export const fetchAuthState = () =>
	new Promise((resolve, reject) => {
		const auth = getAuth(app)
		onAuthStateChanged(auth, async (res) => {
			if (res?.uid) {
				setUserData(res)
					.then((data) => {
						resolve(data)
					})
					// eslint-disable-next-line no-unused-vars
					.catch((err) => {
						reject(new Error('User does not exist', { cause: AUTH_CODES['auth/user-not-found'] }))
					})
			} else {
				const { unsubscribe } = store.state
				if (typeof unsubscribe !== 'undefined') {
					unsubscribe.forEach((snapshot) => snapshot)
					store.commit('setUnsubscribe', [])
				}
				await store.dispatch('updateCurrentCustomer', undefined)
				await store.dispatch('updateCustomerData', undefined)
				store.commit('setUserData', null)

				reject(new Error('User does not exist', { cause: AUTH_CODES['auth/user-not-found'] }))
			}
		})
	})

export const loginWithEmailAndPassword = (email, password) =>
	new Promise((resolve, reject) => {
		const auth = getAuth(app)
		signInWithEmailAndPassword(auth, email, password)
			.then((user) => {
				store.dispatch('updateCurrentCustomer', user)
				showSuccess('User successfully logged in!', 2000)
				resolve(true)
			})
			.catch((error) => {
				// log not required, log should be on the parent call
				showDanger(AUTH_CODES[error.code] || AUTH_ERROR_CODES[error.code] || error.message, 5000)
				reject(error)
			})
	})

// export const signUpWithEmailAndPassword = ({
//   email,
//   password,
//   firstName,
//   lastName,
//   role
// }) => new Promise((resolve, reject) => {
//     const set = {
//       email,
//       createdAt: serverTimestamp(),
//       joinDate: serverTimestamp(),
//       role
//     }

//     if (firstName) {
//       set.firstName = firstName
//     }
//     if (lastName) {
//       set.lastName = lastName
//     }
//     if (firstName && lastName) {
//       const fullName = `${firstName} ${lastName}`;
//       set.fullName = fullName;
//       set.initials = fullName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
//     }

//     const auth = getAuth(app)
//     createUserWithEmailAndPassword(auth, email, password)
//       .then(async (userCredential) => {
//         const { user } = userCredential
//         const { uid } = user
//         await setDoc(doc(db, "customers", uid), set)
//           .catch((err) => {
//             const { code, message } = err;
//             showDanger(AUTH_CODES[code] || AUTH_ERROR_CODES[code] || message, 5000)
//           })
//         store.dispatch('updateCurrentCustomer', userCredential)
//         showSuccess('New user has been successfully signed up!', 5000)
//         resolve(true)
//       })
//       .catch((error) => {
//         const { code, message } = error;
//         showDanger(AUTH_CODES[code] || AUTH_ERROR_CODES[code] || message, 5000)
//         reject(error)
//       })
//   })

export const signUpUser = ({ email, password, firstName, lastName, role }) =>
	new Promise((resolve, reject) => {
		const set = {
			email,
			password,
			firstName,
			lastName,
			role
		}

		const createUser = httpsCallable(functions, 'createUser')
		createUser(set)
			.then(async (result) => {
				store.dispatch('updateCurrentCustomer', result.data.user)
				store.dispatch('updateCurrentAccount', result.data.account)
				resolve(result.data)
			})
			.catch((error) => {
				const { code, message } = error
				showDanger(AUTH_CODES[code] || AUTH_ERROR_CODES[code] || message, 5000)
				reject(error)
			})
	})
