import { useToast } from 'vue-toastification'

const toast = useToast()
toast.timeout = 2000

export default {
	namespaced: true,
	actions: {
		success(_, message) {
			toast.success(message)
		},
		error(_, message) {
			toast.error(message)
		},
		warning(_, message) {
			toast.warning(message)
		},
		info(_, message) {
			toast.info(message)
		},
		default(_, message) {
			toast(message)
		}
	}
}
