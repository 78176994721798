<template>
  <!-- Vertical navigation starts -->
  <div class="h-full bg-gray-900 p-4">
    <div class="flex w-full h-full">
      <div class="h-full flex flex-col justify-between">
        <div class="">
          <div class="flex items-center">
            <router-link to="/">
              <img src="@/assets/images/mark.svg" alt="GrowthBank" />
            </router-link>
            <div
              :class="menu === false ? 'hidden' : 'true'"
              class="pl-3"
              id="closed"
            >
              <router-link to="/">
                <img src="@/assets/images/logo.svg" alt="GrowthBank" />
              </router-link>
            </div>
          </div>

          <div class="mt-10 flex items-center relative">
            <div
              arial-label="search"
              id="search"
              class="w-56 relative w-full"
              :class="menu === false ? 'hidden' : ''"
            >
              <div
                :class="menu === false ? 'hidden' : ''"
                class="absolute p-2"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1665 1.66667C13.3065 1.66667 16.6665 5.02667 16.6665 9.16667C16.6665 13.3067 13.3065 16.6667 9.1665 16.6667C5.0265 16.6667 1.6665 13.3067 1.6665 9.16667C1.6665 5.02667 5.0265 1.66667 9.1665 1.66667ZM9.1665 15C12.389 15 14.9998 12.3892 14.9998 9.16667C14.9998 5.94333 12.389 3.33333 9.1665 3.33333C5.94317 3.33333 3.33317 5.94333 3.33317 9.16667C3.33317 12.3892 5.94317 15 9.1665 15ZM16.2373 15.0592L18.5948 17.4158L17.4157 18.595L15.059 16.2375L16.2373 15.0592Z"
                    fill="white"
                  />
                </svg>
              </div>
              <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
              <input
                :class="menu === false ? 'hidden' : ''"
                type="seach"
                class="w-full py-2 pl-10 bg-gray-800 rounded text-base leading-none text-white placeholder-white"
                placeholder="Search"
              />
            </div>
            <div
              id="search1"
              class="py-2"              
              :class="menu === true ? 'hidden' : ''"
            >
              <svg
                :class="menu === true ? '-ml-8' : ''"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.1665 1.66667C13.3065 1.66667 16.6665 5.02667 16.6665 9.16667C16.6665 13.3067 13.3065 16.6667 9.1665 16.6667C5.0265 16.6667 1.6665 13.3067 1.6665 9.16667C1.6665 5.02667 5.0265 1.66667 9.1665 1.66667ZM9.1665 15C12.389 15 14.9998 12.3892 14.9998 9.16667C14.9998 5.94333 12.389 3.33333 9.1665 3.33333C5.94317 3.33333 3.33317 5.94333 3.33317 9.16667C3.33317 12.3892 5.94317 15 9.1665 15ZM16.2373 15.0592L18.5948 17.4158L17.4157 18.595L15.059 16.2375L16.2373 15.0592Z"
                  fill="white"
                />
              </svg>
            </div>
            <button
              aria-label="minimize sidebar"
              id="close"
              class="w-6 h-6 right-0 -mr-7 bg-indigo-500 absolute shadow rounded-full flex items-center justify-center cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
              @click="menu = false"
              :class="menu === false ? 'hidden' : 'true'"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 4L6 8L10 12"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              id="open"
              class="w-6 h-6 right-0 -mr-7 bg-indigo-500 absolute shadow rounded-full flex items-center justify-center cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
              @click="menu = true"
              :class="menu === true ? 'hidden' : 'true'"
            >
              <svg
                aria-label="expand sidebar"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 12L10 8L6 4"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div class="flex items-center">
            <ul aria-orientation="vertical">
              <li
                tabindex="0"
                role="button"
                aria-label="Overview"
                class="cursor-pointer"
                :class="index > 0 ? 'mt-6' : 'mt-10'"
                v-for="(item, index) in menuItems.adminBar" 
                :key="item"
              >
                <router-link :to="item.route" v-html="item.icon"></router-link>
              </li>
            </ul>
            <div id="navi" class="w-full mt-10" :class="menu === false ? 'hidden' : 'true'">
              <p
                class="text-base leading-4 pl-3 cursor-pointer text-gray-400"
                :class="index > 0 ? 'pt-7' : ''"
                v-for="(item, index) in menuItems.adminBar" 
                :key="item"
              >
                <router-link :to="item.route">{{ item.title }}</router-link>
              </p>
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-between relative"
          id="profile"
        >
          <div class="flex items-center">
            <img
              src="https://i.ibb.co/fDyfmVz/pic.png"
              alt="profile picture"
              class="w-7 h-7 rounded-full"
            />
            <p :class="menu === false ? 'hidden' : 'true'" class="text-base leading-4 pl-3 text-gray-100">
              Lavada Marlon
            </p>
          </div>
          <div
            :class="menu === false ? 'hidden' : 'true'"
            @mouseover="tool=true"
            @mouseleave="tool=false"
            @blur="tool=false"
            @focusin="tool=true"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00016 14.6666C4.31816 14.6666 1.3335 11.682 1.3335 7.99998C1.3335 4.31798 4.31816 1.33331 8.00016 1.33331C11.6822 1.33331 14.6668 4.31798 14.6668 7.99998C14.6668 11.682 11.6822 14.6666 8.00016 14.6666ZM7.3335 7.33331V11.3333H8.66683V7.33331H7.3335ZM7.3335 4.66665V5.99998H8.66683V4.66665H7.3335Z"
                fill="white"
              />
            </svg>
            <div
              id="tooltip1"
              role="tooltip"
              :class="tool === false ? 'hidden' : 'true'"
              class="z-20 md:w-52 top-0 md:-mt-2 -mt-8 absolute transition duration-150 ease-in-out ml-8 shadow bg-white p-4 rounded"
            >
              <svg
                class="absolute left-0 -ml-2 -mt-1.5 bottom-0 top-0 h-full"
                width="9px"
                height="16px"
                viewBox="0 0 9 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Tooltips-"
                    transform="translate(-874.000000, -1029.000000)"
                    fill="#FFFFFF"
                  >
                    <g
                      id="Group-3-Copy-16"
                      transform="translate(850.000000, 975.000000)"
                    >
                      <g
                        id="Group-2"
                        transform="translate(24.000000, 0.000000)"
                      >
                        <polygon
                          id="Triangle"
                          transform="translate(4.500000, 62.000000) rotate(-90.000000) translate(-4.500000, -62.000000) "
                          points="4.5 57.5 12.5 66.5 -3.5 66.5"
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <p class="text-sm font-bold text-gray-800 pb-1">
                Profile Picture and name
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between hidden" id="profile1">
          <img
            src="https://i.ibb.co/fDyfmVz/pic.png"
            alt="profile picture"
            class="w-7 h-7 rounded-full"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- Vertical navigation ends -->
</template>

<script>
import menuItems from '@/constants/menu'

export default {
  name: 'AdminBar',
  data() {
    return {
      menu: false,
      tool: false,
      menuItems
    }
  },
  computed: {}
}
</script>