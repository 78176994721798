import { createApp } from 'vue'
import { fetchAuthState } from '@/utils/AuthService'

import toast from 'vue-toastification'
import App from './App.vue'
import router from './router'
import store from './store'

import 'vue-toastification/dist/index.css'
import '@/assets/css/index.css'
import '@/assets/css/quill.snow.css'

const app = createApp(App)
store.dispatch('user/onAuthChange', () => {})

fetchAuthState()
	.then(() => {
		app.use(store).use(router).use(toast).mount('#app')
	})
	.catch((err) => {
		console.error('error is auth', err)
		app.use(store).use(router).use(toast).mount('#app')
	})
