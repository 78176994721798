import { createStore } from 'vuex'
import user from './modules/user'
import toast from './modules/toast'
import account from './modules/account'

const store = createStore({
	state: {
		plans: undefined,
		addOns: undefined,
		courses: undefined,
		announcements: undefined,
		meta: undefined,
		redirect: false,
		currentCustomer: undefined,
		customerData: undefined,
		currentAccount: undefined,
		accountData: undefined,
		currentPlan: undefined,
		planData: undefined,
		completions: undefined,
		documents: undefined,
		alert: undefined,
		unsubscribe: [],
		userData: null
	},
	getters: {
		isPro: (state) =>
			state.currentPlan?.items?.[0]?.price?.product?.name === 'GrowthBank Professional',
		getUserData: (state) => state.userData,
		getPlans: (state) => state.plans,
		getAddOns: (state) => state.addOns,
		getCourses: (state) => state.courses,
		getAnnouncements: (state) => state.announcements,
		getMeta: (state) => state.meta,
		getRedirect: (state) => state.redirect,
		getCurrentCustomer: (state) => state.currentCustomer,
		getCustomerData: (state) => state.customerData,
		getCurrentAccount: (state) => state.currentAccount,
		getAccountData: (state) => state.accountData,
		getCurrentPlan: (state) => state.currentPlan,
		getPlanData: (state) => state.planData,
		getCompletions: (state) => state.completions,
		getDocuments: (state) => state.documents,
		getGenerators: (state) => state.generators,
		getAlert: (state) => state.alert
	},
	mutations: {
		setUserData(state, payload) {
			state.userData = payload
		},

		setPlans(state, payload) {
			state.plans = payload
		},
		setAddOns(state, payload) {
			state.addOns = payload
		},
		setCourses(state, payload) {
			state.courses = payload
		},
		setAnnouncements(state, payload) {
			state.announcements = payload
		},
		setMeta(state, payload) {
			state.meta = payload
		},
		setRedirect(state, boolean) {
			state.redirect = boolean
		},
		setUnsubscribe(state, func) {
			state.unsubscribe = func
		},
		setCurrentCustomer(state, payload) {
			state.currentCustomer = payload
		},
		setCustomerData(state, payload) {
			state.customerData = payload
		},
		setCurrentAccount(state, payload) {
			state.currentAccount = payload
		},
		setAccountData(state, payload) {
			state.accountData = payload
		},
		setCurrentPlan(state, payload) {
			state.currentPlan = payload
		},
		setPlanData(state, payload) {
			state.planData = payload
		},
		setCompletions(state, payload) {
			state.completions = payload
		},
		setDocuments(state, payload) {
			state.documents = payload
		},
		setGenerators(state, payload) {
			state.generators = payload
		},
		setAlert(state, payload) {
			state.alert = payload
		}
	},
	actions: {
		updatePlans({ commit }, payload) {
			commit('setPlans', payload)
		},
		updateAddOns({ commit }, payload) {
			commit('setAddOns', payload)
		},
		updateCourses({ commit }, payload) {
			commit('setCourses', payload)
		},
		updateAnnouncements({ commit }, payload) {
			commit('setAnnouncements', payload)
		},
		updateMeta({ commit }, payload) {
			commit('setMeta', payload)
		},
		updateRedirect({ commit }, payload) {
			commit('setRedirect', payload)
		},
		updateCurrentCustomer({ commit }, userData) {
			commit('setCurrentCustomer', userData)
		},
		updateCustomerData({ commit }, payload) {
			commit('setCustomerData', payload)
		},
		updateCurrentAccount({ commit }, payload) {
			commit('setCurrentAccount', payload)
		},
		updateAccountData({ commit }, payload) {
			commit('setAccountData', payload)
		},
		updateCurrentPlan({ commit }, payload) {
			commit('setCurrentPlan', payload)
		},
		updatePlanData({ commit }, payload) {
			commit('setPlanData', payload)
		},
		updateCompletions({ commit }, payload) {
			commit('setCompletions', payload)
		},
		updateDocuments({ commit }, payload) {
			commit('setDocuments', payload)
		},
		updateGenerators({ commit }, payload) {
			commit('setGenerators', payload)
		},
		showAlert({ commit, dispatch }, payload) {
			dispatch('hideAlert')
			const timer = setTimeout(() => {
				dispatch('hideAlert')
			}, payload.duration || 2000)
			commit('setAlert', { ...payload, timer })
		},
		hideAlert({ commit, getters }) {
			if (getters.getAlert) {
				clearTimeout(getters.getAlert.timer)
				commit('setAlert', undefined)
			}
		}
	},
	modules: { user, toast, account }
})

export default store
