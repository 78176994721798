<template>
	<nav class="bg-white dark:bg-gray-900">
		<div class="px-4 sm:px-6 lg:px-8">
			<div class="flex items-center justify-between h-16" v-if="isLoggedIn">
				<!-- START MENU -->
				<div class="flex items-center">
					<router-link to="/">
						<img src="@/assets/images/logo.svg" alt="GrowthBank" v-if="theme === 'dark'" />
						<img src="@/assets/images/logo-light.svg" alt="GrowthBank" v-else />
						<span class="sr-only">GrowthBank</span>
					</router-link>
					<div class="hidden lg:block">
						<div class="ml-6 flex items-baseline">
							<router-link
								:to="item.route"
								class="nav-item ml-4 px-3 py-2 rounded-md text-sm font-medium focus:outline-none text-gray-700 dark:text-gray-100 focus:text-gray-700 dark:focus:text-white hover:bg-gray-100 dark:hover:bg-gray-700 focus:bg-gray-100 dark:focus:bg-gray-700"
								v-for="item in filteredMenuItems"
								:key="item"
								>{{ item.title }}</router-link
							>
						</div>
					</div>
				</div>
				<!-- END MENU -->
				<!-- START SEARCH BAR -->
				<!--<div class="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
          <div class="max-w-lg w-full lg:max-w-xs">
            <form action="/search" accept-charset="UTF-8" method="get">
              <label class="sr-only" for="q">Search</label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input type="text" name="q" id="q" placeholder="Search" class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-700 placeholder-gray-400 focus:outline-none focus:bg-white sm:text-sm transition duration-150 ease-in-out">
              </div>
            </form>
          </div>
        </div>-->
				<!-- END SEARCH BAR -->
				<!-- START CONTROLS -->
				<!-- USER LOGGED IN -->
				<div class="hidden lg:block">
					<div class="ml-4 flex items-center md:ml-6">
						<div
							@click="setTheme"
							@keydown="setTheme"
							class="relative p-1 cursor-pointer border-2 border-transparent text-gray-400 rounded-full hover:text-gray-700 dark:hover:text-white focus:outline-none focus:text-white focus:bg-gray-700"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								stroke-width="2"
								v-if="theme === 'dark'"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
								/>
							</svg>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-5 w-5"
								viewBox="0 0 20 20"
								fill="currentColor"
								v-if="theme === 'light'"
							>
								<path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
							</svg>
						</div>
						<div
							@click="announcementMenu = !announcementMenu"
							@keydown="announcementMenu = !announcementMenu"
							class="relative p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-700 dark:hover:text-white focus:outline-none focus:text-white focus:bg-gray-700"
							aria_label="Notifications"
						>
							<svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
								/>
							</svg>
							<div
								v-show="announcementMenu"
								class="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg z-50"
							>
								<div>
									<div class="py-1 rounded-md bg-white shadow-xs">
										<div
											class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
											v-for="announcement in announcements"
											:key="announcement"
										>
											<h3 class="text-md font-bold">{{ announcement.title }}</h3>
											<hr class="opacity-10" />
											<div
												class="text-sm text-gray-600 mt-2 mb-4"
												v-html="announcement.content"
											></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- START PROFILE DROP DOWN -->
						<div class="flex-shrink-0 ml-3 relative">
							<div>
								<button
									@click="open = !open"
									class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid cursor-pointer"
									id="user-menu"
									aria-label="User menu"
									aria-haspopup="true"
									x-bind:aria-expanded="open"
								>
									<img
										v-if="showAvatar"
										:src="customerData?.data?.avatar"
										class="h-8 w-8 rounded-full"
										alt=""
									/>
									<div
										v-if="!showAvatar"
										class="bg-gray-300 max-w-xs flex justify-center items-center text-sm rounded-full text-gray-900 focus:outline-none focus:shadow-solid w-10 h-10"
									>
										<span>{{ customerData?.data?.initials }}</span>
									</div>
								</button>
							</div>
							<div
								v-show="open"
								class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50"
							>
								<div class="py-1 rounded-md bg-white shadow-xs">
									<router-link
										:to="item.route"
										class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
										v-for="item in dropDownMenuItems"
										:key="item"
										>{{ item.title }}</router-link
									>
									<div
										class="border-t border-gray-200"
										v-if="customerData?.data?.role === 'administrator'"
									>
										<router-link
											:to="item.route"
											class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
											v-for="item in adminDropDownMenuItems"
											:key="item"
											>{{ item.title }}</router-link
										>
									</div>
									<a
										@click="signOut"
										@keydown="signOut"
										class="border-t border-gray-200 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
										rel="nofollow"
									>
										Sign Out
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- END USER LOGGED IN -->
				<!-- END CONTROLS -->
				<div class="-mr-2 flex lg:hidden">
					<button
						@click="mobileOpen = !mobileOpen"
						class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
						x-bind:aria-label="mobileOpen ? 'Close main menu' : 'Main menu'"
						x-bind:aria-expanded="open"
					>
						<svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
							<path
								:class="{ hidden: mobileOpen, 'inline-flex': !mobileOpen }"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M4 6h16M4 12h16M4 18h16"
							/>
							<path
								:class="{ hidden: !mobileOpen, 'inline-flex': mobileOpen }"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
		<div :class="[mobileOpen ? 'block' : 'hidden']" class="lg:hidden">
			<div class="px-2 pt-2 pb-3 sm:px-3 flex flex-col divide-y divide-gray-800 bg-gray-900">
				<router-link
					:to="item.route"
					class="px-3 py-4 justify-center rounded-md text-sm font-medium focus:outline-none text-gray-300 focus:text-white hover:bg-gray-700 focus:bg-gray-700"
					v-for="item in filteredMenuItems"
					:key="item"
					>{{ item.title }}</router-link
				>
				<div
					class="flex flex-col divide-y divide-gray-800"
					v-if="customerData?.data?.role === 'administrator'"
				>
					<router-link
						:to="item.route"
						class="px-3 py-4 justify-center rounded-md text-sm font-medium focus:outline-none text-gray-300 focus:text-white hover:bg-gray-700 focus:bg-gray-700"
						v-for="item in adminDropDownMenuItems"
						:key="item"
						>{{ item.title }}</router-link
					>
				</div>
				<a
					@click="signOut"
					@keydown="signOut"
					class="px-3 py-4 justify-center rounded-md text-sm font-medium focus:outline-none text-gray-300 focus:text-white hover:bg-gray-700 focus:bg-gray-700"
					rel="nofollow"
				>
					Sign Out
				</a>
			</div>
		</div>
	</nav>
</template>

<script>
import menuItems from '@/constants/menu'
import AUTH_ERROR_CODES from '@/constants/auth'
import { app } from '@/firebaseInit'
import { getAuth } from 'firebase/auth'
import { showDanger } from '@/utils/AlertService'

export default {
	name: 'NavBar',
	inject: ['customer', 'announcements'],
	props: {
		theme: {
			String
		},
		isPro: {
			Boolean
		}
	},
	components: {},
	data() {
		return {
			menuItems,
			customerData: this.customer,
			open: false,
			mobileOpen: false,
			announcementMenu: false
		}
	},
	computed: {
		announcements() {
			return this.$store.state.announcements
		},
		currentCustomer() {
			return this.$store.state.currentCustomer
		},
		currentPlan() {
			return this.$store.state.currentPlan
		},
		filteredMenuItems() {
			const items = []
			this.menuItems.top.forEach((item) => {
				switch (item.access) {
					case 'pro':
						if (this.$store.getters.isPro) items.push(item)
						break
					default:
						items.push(item)
						break
				}
			})
			return items
		},
		dropDownMenuItems() {
			const items = []
			this.menuItems.dropdown.all.forEach((item) => {
				switch (item.access) {
					case 'pro':
						if (this.$store.getters.isPro) items.push(item)
						break
					default:
						items.push(item)
						break
				}
			})
			return items
		},
		adminDropDownMenuItems() {
			return this.menuItems.dropdown.admin
		},
		showAvatar() {
			return this.customerData?.data?.avatar
		},
		isLoggedIn() {
			return !!this.customerData?.data
		}
	},
	mounted() {
		if (this.currentCustomer) {
			this.loggedIn = true
		}
	},
	watch: {
		customer: {
			handler() {
				this.init()
			},
			deep: true
		}
	},
	methods: {
		init() {},
		async signOut() {
			const auth = getAuth(app)
			await auth
				.signOut()
				.then(() => {
					if (this.open) {
						this.open = false
					}
					this.$store.dispatch('updateCurrentCustomer', undefined)
					this.$store.dispatch('updateCustomerData', undefined)
					this.$store.dispatch('updateCurrentAccount', undefined)
					this.$store.dispatch('updateAccountData', undefined)
					this.$store.dispatch('updateCurrentPlan', undefined)
					this.$store.dispatch('updatePlanData', undefined)
					this.$store.dispatch('updateDocuments', undefined)
					// this.$store.dispatch('setRedirect', undefined)
					// this.$router.push({ name: 'login' })
					const routeData = this.$router.resolve({
						name: 'login'
					})
					window.open(routeData.href, '_self')
				})
				.catch((err) => {
					// console.error('🚀 ~ file: NavBar.vue ~ line 135 ~ handleLogout ~ error', err)
					showDanger(AUTH_ERROR_CODES[err.code] || err.message, 5000)
				})
		},
		setTheme() {
			this.$emit('updateTheme')
		}
	}
}
</script>
<style scoped>
.nav-item.router-link-active {
	@apply bg-gray-100 dark:bg-gray-700;
}
</style>
