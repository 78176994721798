import { doc, setDoc, getDoc, query, where, collection, getDocs } from 'firebase/firestore'
import { db } from '../../firebaseInit'

export default {
	namespaced: true,
	state() {
		return {
			data: null,
			teamMembers: []
		}
	},
	actions: {
		async getUserAccount({ commit }, user) {
			const accountRef = doc(db, 'accounts', user.id)
			const account = await getDoc(accountRef)

			const accountData = {
				id: user.id,
				email: user.email,
				...account.data(),
				subscriptions: []
			}

			const q = query(
				collection(db, 'accounts', user.id, 'subscriptions'),
				where('status', 'in', ['trialing', 'active'])
			)

			const querySnapshot = await getDocs(q)

			querySnapshot.forEach((subscription) => {
				accountData.subscriptions.push(subscription.data())
			})

			commit('setAccount', accountData)
		},
		async createAccount(_, { id, ...account }) {
			await setDoc(doc(db, 'accounts', id), { ...account, id })
		},
		async getTeamMembers({ rootState, dispatch, commit }) {
			const { id } = rootState.user.data
			if (!id) {
				dispatch('toast/error', 'User is not logged in!', { root: true })
			}

			if (rootState.account.data.users) {
				const teamMembersQuery = query(
					collection(db, 'users'),
					where('id', 'in', rootState.account.data.users)
				)

				const teamMembersSnap = await getDocs(teamMembersQuery)
				const teamMembers = await Promise.all(teamMembersSnap.docs.map((user) => user.data()))

				commit('setTeamMembers', teamMembers)
			} else {
				commit('setTeamMembers', [])
			}
		}
	},
	mutations: {
		setAccount(state, account) {
			state.data = account
		},
		updateAccount(state, account) {
			state.data = { ...state.data, ...account }
		},
		setTeamMembers(state, teamMembers) {
			state.teamMembers = teamMembers
		}
	}
}
