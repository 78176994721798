// import Login from './middleware/login';

const routes = [
	{
		path: '/',
		name: 'dashboard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue'),
		meta: {
			title: 'Dashboard - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			// middleware: [Login],
			access: 'any',
			auth: true,
			blockLoading: true
		}
	},
	{
		path: '/profile',
		name: 'profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
		children: [
			{
				path: '',
				name: 'profilePersonal',
				component: () =>
					import(
						/* webpackChunkName: "profilePersonalTabView" */ '../views/profile-view/PersonalTabView.vue'
					)
			},
			{
				path: 'workspace',
				name: 'profileWorkspace',
				access: 'owner',
				component: () =>
					import(
						/* webpackChunkName: "profileWorkspaceTabView" */ '../views/profile-view/WorkspaceTabView.vue'
					)
			},
			{
				path: 'team',
				name: 'profileTeam',
				access: 'owner',
				component: () =>
					import(
						/* webpackChunkName: "profileTeamTabView" */ '../views/profile-view/TeamTabView.vue'
					)
			},
			{
				path: 'billing',
				name: 'profileBilling',
				access: 'owner',
				component: () =>
					import(
						/* webpackChunkName: "profileBillingTabView" */ '../views/profile-view/BillingTabView.vue'
					)
			},
			{
				path: 'usage',
				name: 'profileUsage',
				access: 'owner',
				component: () =>
					import(
						/* webpackChunkName: "profileUsageTabView" */ '../views/profile-view/UsageTabView.vue'
					)
			}
		],
		meta: {
			title: 'Profile - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			// middleware: [Login],
			access: 'any',
			auth: true,
			blockLoading: true
		}
	},
	// {
	// 	path: '/profile/:tab',
	// 	name: 'profile_tab',
	// 	component: () => import(/* webpackChunkName: "profile_tab" */ '../views/ProfileView.vue'),
	// 	meta: {
	// 		title: 'Profile - GrowthBank',
	// 		metaTags: [
	// 			{
	// 				name: 'description',
	// 				content: 'The home page of our example app.'
	// 			},
	// 			{
	// 				property: 'og:description',
	// 				content: 'The home page of our example app.'
	// 			}
	// 		],
	// 		// middleware: [Login],
	// 		access: 'any',
	// 		auth: true,
	// 		blockLoading: true
	// 	}
	// },
	{
		path: '/documents',
		name: 'documents',
		component: () => import(/* webpackChunkName: "documents" */ '../views/DocumentsView.vue'),
		meta: {
			title: 'Documents - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			// middleware: [Login],
			access: 'any',
			auth: true,
			blockLoading: true
		}
	},
	{
		path: '/documents/:id',
		name: 'document',
		component: () => import(/* webpackChunkName: "document" */ '../views/DocumentView.vue'),
		meta: {
			title: 'Document - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			// middleware: [Login],
			access: 'any',
			auth: true,
			blockLoading: true
		},
		props: (route) => ({
			title: route.name,
			crumbs: [
				{
					title: 'Documents',
					path: '/documents'
				}
			]
		})
	},
	{
		path: '/generators',
		name: 'generators',
		component: () => import(/* webpackChunkName: "generators" */ '../views/GeneratorsView.vue'),
		meta: {
			title: 'Generators - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			// middleware: [Login],
			access: 'any',
			auth: true,
			blockLoading: true
		}
	},
	{
		path: '/generators/:slug',
		name: 'generator',
		component: () => import(/* webpackChunkName: "generator" */ '../views/GeneratorView.vue'),
		meta: {
			title: 'Generators - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			// middleware: [Login],
			access: 'any',
			auth: true,
			blockLoading: true
		},
		props: (route) => ({
			title: route.name,
			crumbs: [
				{
					title: 'Generators',
					path: '/generators'
				}
			]
		})
	},
	{
		path: '/workflows',
		name: 'workflows',
		component: () => import(/* webpackChunkName: "workflows" */ '../views/WorkflowsView.vue'),
		meta: {
			title: 'Workflows - GrowthBank',
			access: 'any',
			auth: true,
			blockLoading: true
		}
	},
	{
		path: '/workflows/blog-post-workflow',
		name: 'blog-post-workflow',
		component: () =>
			import(
				/* webpackChunkName: "blog-post-workflow" */ '../components/workflows/BlogPostWorkflow.vue'
			),
		meta: {
			title: 'Workflows - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			// middleware: [Login],
			access: 'any',
			auth: true,
			blockLoading: true
		},
		props: (route) => ({
			title: route.name,
			crumbs: [
				{
					title: 'Workflows',
					path: '/workflows'
				}
			]
		})
	},
	{
		path: '/strategy',
		name: 'strategy',
		component: () => import(/* webpackChunkName: "strategy" */ '../views/StrategyView.vue'),
		meta: {
			title: 'Strategy - GrowthBank',
			access: 'any',
			auth: true,
			blockLoading: true
		}
	}
]

export default routes
