/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router'

import userRoutes from '@/router/userRoutes'
import sharedRoutes from '@/router/sharedRoutes'
import adminRoutes from '@/router/adminRoutes'
// import { fetchAuthState } from '@/utils/AuthService'
import store from '@/store'

const routes = [...sharedRoutes, ...adminRoutes, ...userRoutes]
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
// eslint-disable-next-line no-unused-vars
function nextFactory(context, middleware, index) {
	const subsequentMiddleware = middleware[index]
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) return context.next()

	return (...parameters) => {
		// Run the default Vue Router `next()` callback first.
		context.next(...parameters)
		// Then run the subsequent Middleware with a new
		// `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(context, middleware, index + 1)
		subsequentMiddleware({ ...context, next: nextMiddleware })
	}
}

// router.beforeEach((to, from, next) => {
//   const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
//   const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
//   const previousNearestWithMeta = from.matched.slice().reverse()
//     .find((r) => r.meta && r.meta.metaTags);
//   if (nearestWithTitle) {
//     document.title = nearestWithTitle.meta.title;
//   } else if (previousNearestWithMeta) {
//     document.title = previousNearestWithMeta.meta.title;
//   }
//   Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
//   if (!nearestWithMeta) return next();
//   nearestWithMeta.meta.metaTags.map((tagDef) => {
//     const tag = document.createElement('meta');

//     Object.keys(tagDef).forEach((key) => {
//       tag.setAttribute(key, tagDef[key]);
//     });

//     // We use this to track which meta tags we create so we don't interfere with other ones.
//     tag.setAttribute('data-vue-router-controlled', '');

//     return tag;
//   }).forEach((tag) => document.head.appendChild(tag));

//   return next();
// });

// router.beforeEach(async (to, from, next) => {
// 	window.scrollTo(0, 0)
// 	// if (!to.matched.length) {
// 	// 	return router.push({ name: 'login' })
// 	// }
// 	try {
// 		console.log('entering')
// 		const auth = await fetchAuthState()

// 		console.log('auth object is ', auth)
// 		// if (auth) {
// 		// 	if (to.meta.access !== 'any') {
// 		// 		// do some stuff here
// 		// 	}
// 		// 	//  go next
// 		// } else {
// 		// 	// if (to.meta.auth === undefined) next()
// 		// 	// if(to.meta)
// 		// 	// redirect to login page
// 		// }

// 		// if (to.meta.auth === true) {
// 		// } else if (to.meta.auth === false) {
// 		// } else {
// 		// }

// 		// if (to.meta.access === 'owner' && auth) {
// 		// 	redirect = 'next'
// 		// }

// 		// if (to.meta.access === 'member' && auth) {
// 		// 	redirect = 'next'
// 		// }

// 		// if (to.meta.access === 'administrator' && auth) {
// 		// 	redirect = 'next'
// 		// }

// 		// if (auth && to.name === 'login') {
// 		// 	redirect = 'dashboard'
// 		// }
// 		next()
// 	} catch (error) {
// 		console.log('error in ayt')
// 		console.log(error)
// 	}

// 	// if (to.meta.middleware && from) {
// 	// 	const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
// 	// 	const context = {
// 	// 		from,
// 	// 		next,
// 	// 		router,
// 	// 		to
// 	// 	}
// 	// 	const nextMiddleware = nextFactory(context, middleware, 1)

// 	// 	return middleware[0]({ ...context, next: nextMiddleware })
// 	// }
// 	// return next()
// })
function getLatestMilestone(userData) {
	let latestMilestone = userData.milestones[0]
	if (userData.account.keyTopics?.length) {
		latestMilestone = {
			id: 'COMPLETED_ONBOARDING'
		}
	}
	if (userData.subscriptions.length) {
		latestMilestone = {
			id: 'PURCHASED_PLAN'
		}
	}
	return latestMilestone
}

router.beforeEach((to, from, next) => {
	const userData = store.getters.getUserData

	const isAuthPage = to.matched.some((record) => record.meta.auth === true)
	const isNomralRoute = to.matched.some((record) => record.meta.nature === 'normal')

	const isAuthenticatedRoute = to.matched.some((record) => record.meta.routeStatus === 'auth')

	const isAccountCompletionRoute = to.matched.some(
		(record) => record.meta.type === 'accountCompletion'
	)

	const routeAccess = to.meta.access
	const isPro = to.meta.plan === 'pro'
	if (isNomralRoute) {
		// if it's a normal route , like blog etc , or terms and policies ,just go ahead
		next()
		// normal route will not be effected 'auth' prop
	} else {
		if (!isAuthPage) {
			// login and signup pagess
			if (!userData) {
				next()
			} else {
				next('/')
			}
		} else {
			// other auth page like dashboard and admin dashboard
			if (!userData) {
				next('/login')
			} else {
				const latestMilestone = getLatestMilestone(userData)
				const access = userData.role

				// check the milestones
				if (latestMilestone.id !== 'PURCHASED_PLAN') {
					if (latestMilestone.id === 'CREATED_ACCOUNT') {

							if (to.name !== 'onboarding') {
								next({ name: 'onboarding' })
							} else {
								next()
							}
					}
					if (latestMilestone.id === 'COMPLETED_ONBOARDING') {
						if (to.name !== 'pricing') {
							next({ name: 'pricing' })
						} else {
							next()
						}
					}
				} else {
					if (isAccountCompletionRoute) {
						next('/')
					} else {
						if (routeAccess === 'any') {
							// plans check
							if (isPro) {
								if (store.getters.isPro) {
									next()
								} else {
									next('/')
								}
							} else {
								next()
							}
						} else {
							if (routeAccess !== access) {
								if (to.fullPath === '/') {
									next()
								} else {
									next('/')
								}
							} else {
								if (access === 'administrator') {
									next()
								} else {
									// plans
									// is plan protected
									if (isPro) {
										if (store.getters.isPro) {
											next()
										} else {
											next('/')
										}
									} else {
										next()
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// if (isAuthenticatedRoute && !userData) {
	// 	next('/login')
	// } else {
	// 	let nextRoute = null

	// 	const access = userData.role

	// 	const isSharedRoute = to.matched.some((record) => record.meta.auth === false)
	// 	const isRestrictedRoute = to.matched.some((record) => record.meta.auth === true)

	// 	if (latestMilestone.id === 'CREATED_ACCOUNT' && to.name !== 'onboarding') {
	// 		nextRoute = { name: 'onboarding' }
	// 	}
	// 	if (latestMilestone.id === 'COMPLETED_ONBOARDING' && to.name !== 'pricing') {
	// 		nextRoute = { name: 'pricing' }
	// 	}
	// 	if (latestMilestone.id === 'PURCHASED_PLAN' && isSharedRoute) {
	// 		console.log('in sharedRoutes')
	// 		nextRoute = { name: 'dashboard' }
	// 	}
	// 	if (latestMilestone.id === 'PURCHASED_PLAN' && isRestrictedRoute) {
	// 		// if (routeAccess !== 'any') {
	// 		// 	if (routeAccess !== access) {
	// 		// 		nextRoute = { name: 'dashboard' }
	// 		// 	}
	// 		// }
	// 	}
	// 	// next(nextRoute)
	// 	if (nextRoute) next(nextRoute)
	// 	else next()
	// }
	// next()
})

export default router
