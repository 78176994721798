<template>
	<div class="relative h-full min-h-full" :class="[showAdminBar ? 'flex flex-no-wrap' : '']">
		<alert-view v-if="getAlert" />
		<nav-bar
			@updateTheme="updateTheme"
			:theme="theme"
			v-if="active && !showAdminBar"
			:isPro="isPro"
		/>
		<admin-bar v-if="active && showAdminBar"></admin-bar>
		<router-view
			:key="$route.fullPath"
			:isPro="isPro"
			class="w-full overflow-y-scroll"
			:class="showAdminBar ? 'p-4' : ''"
		/>
	</div>
</template>

<script>
import store from '@/store/index'
import { db } from '@/firebaseInit'
import { query, collection, orderBy, limit, onSnapshot } from 'firebase/firestore'
import * as Stripe from '@/utils/StripeService'
import { showDanger } from '@/utils/AlertService'
import { computed } from 'vue'
import NavBar from '@/components/navigation/NavBar.vue'
import AdminBar from '@/components/navigation/AdminBar.vue'
import AlertView from '@/components/Alert.vue'

// eslint-disable-next-line no-unused-vars
import { mapGetters, mapState } from 'vuex'

export default {
	components: {
		NavBar,
		AdminBar,
		AlertView
	},
	data() {
		return {
			theme: 'light',
			loading: false,
			blockLoading: this.$route.meta.blockLoading
		}
	},
	provide() {
		return {
			customer: computed(() => this.$store.state.customerData),
			account: computed(() => this.account),
			plans: computed(() => this.$store.state.plans),
			documents: computed(() => this.$store.state.documents),
			generators: computed(() => this.$store.state.generators),
			completions: computed(() => this.$store.state.completions),
			announcements: computed(() => this.$store.state.announcements)
		}
	},
	created() {
		this.loading = true
		if (
			localStorage.theme === 'dark' ||
			(!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
		) {
			document.documentElement.classList.add('dark')
			document.documentElement.classList.remove('light')
			this.theme = 'dark'
		} else {
			document.documentElement.classList.remove('dark')
			document.documentElement.classList.add('light')
			this.theme = 'light'
		}

		Stripe.default
			.retrieveProducts()
			.then((products) => {
				const plans = []
				const addOns = []
				const courses = []
				const snapshots = store.state.unsubscribe
				products.forEach((product) => {
					switch (product.metadata.type) {
						case 'plan':
							plans.push(product)
							snapshots.push(product)
							break
						case 'add-on':
							addOns.push(product)
							snapshots.push(product)
							break
						case 'course':
							courses.push(product)
							snapshots.push(product)
							break
						default:
							break
					}
				})
				store.dispatch('updatePlans', plans)
				store.dispatch('updateAddOns', addOns)
				store.dispatch('updateCourses', courses)
				store.commit('setUnsubscribe', snapshots)
			})
			.catch((err) => {
				const { message } = err
				showDanger(message, 5000)
			})

		let q = query(collection(db, 'announcements'), orderBy('createdAt', 'desc'), limit(4))
		onSnapshot(q, (querySnapshot) => {
			const announcements = []
			querySnapshot.forEach((doc) => {
				const announcement = doc.data()
				announcements.push(announcement)
				const snapshots = store.state.unsubscribe
				snapshots.push(doc.data())
				store.commit('setUnsubscribe', snapshots)
			})
			store.dispatch('updateAnnouncements', announcements)
		})

		q = query(collection(db, 'generators'), orderBy('title', 'asc'))
		onSnapshot(q, (querySnapshot) => {
			const generators = []
			querySnapshot.forEach((doc) => {
				const generator = doc.data()
				generators.push(generator)
				const snapshots = store.state.unsubscribe
				snapshots.push(doc.data())
				store.commit('setUnsubscribe', snapshots)
			})
			store.dispatch('updateGenerators', generators)
		})
	},
	mounted() {
		if (!this.blockLoading) {
			this.loading = false
		}
	},
	computed: {
		...mapGetters(['getAlert']),
		// ...mapState([
		// 	// 'currentCustomer',
		// 	// 'currentAccount',
		// 	// 'plans',
		// 	'currentPlan'
		// 	// { account: 'accountData' }
		// ]),
		store() {
			return this.$store
		},
		currentCustomer() {
			return this.$store.state.currentCustomer
		},
		currentAccount() {
			return this.$store.state.currentAccount
		},
		plans() {
			return this.$store.state.plans
		},
		currentPlan() {
			return this.$store.state.currentPlan
		},
		isPro() {
			if (this.currentPlan) {
				const { items } = this.currentPlan
				const { price } = items[0]
				const { product } = price
				if (product.name === 'GrowthBank Professional') {
					return true
				}
			}
			return false
			// return true
		},
		account() {
			return this.$store.state.accountData
		},
		customer() {
			return this.$store.getters.getCustomerData
		},
		active() {
			return true
		},
		announcements() {
			return this.$store.state.announcements
		},
		completions() {
			return this.$store.state.completions
		},
		documents() {
			return this.$store.state.documents
		},
		generators() {
			return this.$store.state.generators
		},
		isAdminRoute() {
			if (this.$route.name) {
				return this.$route.name.toString().includes('admin')
			}
			return false
		},
		showAdminBar() {
			if (this.customer?.data?.role === 'administrator' && this.isAdminRoute) {
				return true
			}
			return false
		}
	},
	watch: {
		active: {
			handler(val) {
				if (val === true) {
					this.loading = false
				}
			}
		},
		loading: {
			handler(val) {
				if (val === true) {
					document.documentElement.classList.add('loading')
				} else {
					document.documentElement.classList.remove('loading')
				}
			}
		}
	},
	methods: {
		updateTheme() {
			switch (this.theme) {
				case 'dark':
					document.documentElement.classList.remove('dark')
					document.documentElement.classList.add('light')
					this.theme = 'light'
					localStorage.theme = 'light'
					break
				default:
					document.documentElement.classList.add('dark')
					document.documentElement.classList.remove('light')
					this.theme = 'dark'
					localStorage.theme = 'dark'
					break
			}
		}
	}
}
</script>

<style lang="scss">
body.modal-open {
	overflow: hidden;
}
</style>
