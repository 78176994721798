// import Login from './middleware/login';

const routes = [
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import(/* webpackChunkName: "admin-dashboard" */ '../views/admin/DashboardView.vue'),
    meta: {
      title: 'Dashboard - GrowthBank',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.',
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.',
        },
      ],
      // middleware: [Login],
      access: 'administrator',
      auth: true,
      blockLoading: true
    },
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import(/* webpackChunkName: "admin-dashboard" */ '../views/admin/DashboardView.vue'),
    meta: {
      title: 'Dashboard - GrowthBank',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.',
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.',
        },
      ],
      // middleware: [Login],
      access: 'administrator',
      restricted: true,
			auth: true,

      blockLoading: true
    },
  },
  {
    path: '/admin/accounts',
    name: 'admin-accounts',
    component: () => import(/* webpackChunkName: "admin-accounts" */ '../views/admin/AccountsView.vue'),
    meta: {
      title: 'Accounts - GrowthBank',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.',
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.',
        },
      ],
      // middleware: [Login],
      access: 'administrator',
      restricted: true,
			auth: true,
      blockLoading: true
    },
  },
  {
    path: '/admin/accounts/:id',
    name: 'admin-account',
    component: () => import(/* webpackChunkName: "admin-account" */ '../views/admin/AccountView.vue'),
    meta: {
      title: 'Account - GrowthBank',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.',
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.',
        },
      ],
      // middleware: [Login],
      access: 'administrator',
      restricted: true,
			auth: true,
      blockLoading: true
    },
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import(/* webpackChunkName: "admin-users" */ '../views/admin/MembersView.vue'),
    meta: {
      title: 'Dashboard - GrowthBank',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.',
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.',
        },
      ],
      // middleware: [Login],
      access: 'administrator',
      restricted: true,
			auth: true,

      blockLoading: true
    },
  },
  {
    path: '/admin/products',
    name: 'admin-products',
    component: () => import(/* webpackChunkName: "admin-products" */ '../views/admin/ProductsView.vue'),
    meta: {
      title: 'Dashboard - GrowthBank',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.',
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.',
        },
      ],
      // middleware: [Login],
      access: 'administrator',
      restricted: true,
			auth: true,

      blockLoading: true
    },
  },
  {
    path: '/admin/generators',
    name: 'admin-generators',
    component: () => import(/* webpackChunkName: "admin-gemerators" */ '../views/admin/GeneratorsView.vue'),
    meta: {
      title: 'Generators - GrowthBank',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.',
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.',
        },
      ],
      // middleware: [Login],
      access: 'administrator',
      restricted: true,
			auth: true,

      blockLoading: true
    },
  },
  {
    path: '/admin/generators/:id',
    name: 'admin-generator',
    component: () => import(/* webpackChunkName: "admin-generator" */ '../views/admin/GeneratorView.vue'),
    meta: {
      title: 'Edit Generator - GrowthBank',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.',
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.',
        },
      ],
      // middleware: [Login],
      access: 'administrator',
      restricted: true,
			auth: true,
      blockLoading: true
    },
  },
  {
    path: '/admin/workflows',
    name: 'admin-workflows',
    component: () => import(/* webpackChunkName: "admin-workflows" */ '../views/admin/WorkflowsView.vue'),
    meta: {
      title: 'Dashboard - GrowthBank',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.',
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.',
        },
      ],
      // middleware: [Login],
      access: 'administrator',
      restricted: true,
			auth: true,

      blockLoading: true
    },
  }
]

export default routes;
