import { app } from '@/firebaseInit'
import { getAuth } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'

import {
	createCheckoutSession,
	getProducts,
	getStripePayments
} from '@stripe/firestore-stripe-payments'

const payments = getStripePayments(app, {
	productsCollection: 'plans',
	customersCollection: 'accounts'
})

const retrieveProducts = async () =>
	getProducts(payments, {
		includePrices: true,
		activeOnly: true
	})

const checkout = async (priceId, name) =>
	createCheckoutSession(payments, {
		customer_name: name,
		price: priceId,
		trial_from_plan: true,
		allow_promotion_codes: true,
		phone_number_collection: {
			enabled: true
		},
		success_url: 'https://members.growthbank.app/',
		cancel_url: 'https://members.growthbank.app/pricing'
	})

const isUserProfessional = async () => {
	const auth = getAuth(app)
	await auth.currentUser?.getIdToken(true)
	const decodedToken = await auth.currentUser?.getIdTokenResult()
	return !!decodedToken?.claims?.stripeRole
}

const createPortalLink = async () => {
  // const functions = getFunctions(app)
	// const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink')
	// const { data } = await functionRef({
	// 	returnUrl: window.location.origin
	// })
	// return data
  
  const functions = getFunctions(app, 'us-central1')
  const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
  const { data } = await functionRef({
    returnUrl: window.location.origin
  });
  return data
}

export default { payments, retrieveProducts, checkout, isUserProfessional, createPortalLink }
