import {
	getAuth,
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
	signInWithEmailAndPassword
} from 'firebase/auth'
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { db, storage, app } from '../../firebaseInit'

export default {
	namespaced: true,
	state() {
		return {
			data: null,
			auth: {
				isProcessing: false,
				error: ''
			}
		}
	},
	getters: {
		isAuthenticated(state) {
			return !!state.data
		},
		isOwner(state) {
			return state.data.role === 'owner'
		}
	},
	actions: {
		async uploadImage(_, { bytes, path, onSuccess, onProgress }) {
			const storageRef = ref(storage, path)
			const uploadTask = uploadBytesResumable(storageRef, bytes)

			uploadTask.on(
				'state_changed',
				(snapshot) => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
					onProgress(progress)
				},
				(error) => {
					console.error(error.message)
				},
				async () => {
					const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
					onSuccess(downloadUrl)
				}
			)
		},
		onAuthChange({ dispatch, commit }, callback) {
			commit('setAuthIsProcessing', true)
			onAuthStateChanged(getAuth(app), async (user) => {
				if (user) {
					await dispatch('getUserProfile', user)
					commit('setAuthIsProcessing', false)
					callback(user)
				} else {
					console.log('Logged out')
					commit('setAuthIsProcessing', false)
					callback(null)
				}
			})
		},
		async updateProfile({ commit, dispatch }, { data, onSuccess }) {
			try {
				const userRef = doc(db, 'users', data.id)
				await updateDoc(userRef, data)
				commit('updateProfile', data)
				dispatch('toast/success', 'Profile has been updated!', { root: true })
				onSuccess()
			} catch (e) {
				dispatch('toast/error', e.message, { root: true })
			}
		},
		async getUserProfile({ commit }, user) {
			const docRef = doc(db, 'users', user.uid)
			const docSnap = await getDoc(docRef)
			const userProfile = docSnap.data()

			const userWithProfile = {
				id: user.uid,
				email: user.email,
				...userProfile
			}

			commit('setUser', userWithProfile)
		},
		async register({ commit, dispatch }, { email, password, username }) {
			commit('setAuthIsProcessing', true)
			commit('setAuthError', '')

			try {
				const { user } = await createUserWithEmailAndPassword(getAuth(app), email, password)
				await dispatch('createUserProfile', {
					id: user.uid,
					username,
					avatar:
						'https://www.pinclipart.com/picdir/middle/133-1331433_free-user-avatar-icons-happy-flat-design-png.png'
				})
			} catch (e) {
				commit('setAuthError', e.message)
				dispatch('toast/error', e.message, { root: true })
			} finally {
				commit('setAuthIsProcessing', false)
			}
		},
		async login({ commit, dispatch }, { email, password }) {
			commit('setAuthIsProcessing', true)
			commit('setAuthError', '')

			try {
				await signInWithEmailAndPassword(getAuth(app), email, password)
			} catch (e) {
				commit('setAuthError', e.message)
				dispatch('toast/error', e.message, { root: true })
			} finally {
				commit('setAuthIsProcessing', false)
			}
		},
		async logout({ commit }) {
			try {
				await signOut(getAuth(app))
				commit('setUser', null)
			} catch (e) {
				console.error('Cannot logout!')
			}
		},
		async createUserProfile(_, { id, ...profile }) {
			await setDoc(doc(db, 'users', id), { ...profile, id })
		}
	},
	mutations: {
		setAuthIsProcessing(state, isProcessing) {
			state.auth.isProcessing = isProcessing
		},
		setAuthError(state, error) {
			state.auth.error = error
		},
		setUser(state, user) {
			state.data = user
		},
		updateProfile(state, profile) {
			state.data = { ...state.data, ...profile }
		}
	}
}
