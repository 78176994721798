const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
		meta: {
			title: 'Login - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			access: 'any',
			routeStatus: 'authPage',
			auth: false,
			blockLoading: false
		},
		props: (route) => ({
			title: route.name,
			crumbs: [
				{
					title: 'Workflows',
					path: '/workflows'
				}
			]
		})
	},
	{
		path: '/signup',
		name: 'signup',
		component: () => import(/* webpackChunkName: "signup" */ '../views/SignUpView.vue'),
		meta: {
			title: 'Signup - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			access: 'any',
			auth: false,
			routeStatus: 'authPage',
			blockLoading: false
		}
	},
  {
		path: '/reset-password',
		name: 'resetPassword',
		component: () => import(/* webpackChunkName: "reset" */ '../views/ResetPasswordView.vue'),
		meta: {
			title: 'Reset password - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			access: 'any',
			routeStatus: 'authPage',
			auth: false,
			blockLoading: false
		}
	},
  {
		path: '/verify-email',
		name: 'verifyEmail',
		component: () => import(/* webpackChunkName: "verify" */ '../views/EmailVerification.vue'),
		meta: {
			title: 'Email Verification - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			access: 'any',
			auth: false,
			routeStatus: 'authPage',
			blockLoading: false
		},
    props: true
	},
	{
		path: '/onboarding',
		name: 'onboarding',
		component: () => import(/* webpackChunkName: "onboarding" */ '../views/OnboardingView.vue'),
		meta: {
			title: 'Onboarding - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			access: 'any',
			auth: true,
			routeStatus: 'auth',
			type: 'accountCompletion',
			blockLoading: true
		}
	},
	{
		path: '/pricing',
		name: 'pricing',
		component: () => import(/* webpackChunkName: "pricing" */ '../views/PricingView.vue'),
		meta: {
			title: 'Select a Plan - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			access: 'any',
			nature: 'normal',
			blockLoading: false
		}
	},
	{
		path: '/checkout-success',
		name: 'checkout-success',
		component: () => import(/* webpackChunkName: "checkout-success" */ '../views/PricingView.vue'),
		meta: {
			title: 'Select a Plan - GrowthBank',
			metaTags: [
				{
					name: 'description',
					content: 'The home page of our example app.'
				},
				{
					property: 'og:description',
					content: 'The home page of our example app.'
				}
			],
			access: 'any',
			nature: 'normal',
			blockLoading: false
		}
	}
]

export default routes;